<template>
  <div class="md-layout md-alignment-top-space-between auth-panel md-elevation-5">
    <div class="md-layout-item md-size-50 login-form register-form">
      <md-card class="md-elevation-0 signup-container">
        <form novalidate class="md-layout md-alignment-center-center" @submit.prevent="validateRegister">
          <md-card-header>
            <div class="small-login-logo">
              <img alt="wibki logo" :src="`${publicPath}wibki.png`">
            </div>
          </md-card-header>

          <md-card-content class="register-container">
            <md-field :class="[getValidationClass('username'), 'md-field-auth']">
              <md-input name="username" id="username" :autocomplete="$t('register.username')" v-model="form.username"
                :disabled="isLoading" @focus="clearErrors('username')" class="md-input-auth input-with-border"
                placeholder="Username" />
              <div class="md-error" v-if="!$v.form.username.required">
                {{ $t('register.requiredField') }}
              </div>
              <div class="md-error" v-else-if="!$v.form.username.minLength">
                {{ $t('register.minLengthUsername') }}
              </div>
            </md-field>

            <md-field :class="[getValidationClass('email'), 'md-field-auth']">
              <md-input name="email" id="email" :autocomplete="$t('register.email')" v-model="form.email"
                :disabled="isLoading" @focus="clearErrors('email')" class="md-input-auth input-with-border"
                placeholder="Email" />
              <div class="md-error" v-if="!$v.form.email.required">
                {{ $t('register.requiredField') }}
              </div>
              <div class="md-error" v-else-if="!$v.form.email.email">
                {{ $t('register.invalidEmail') }}
              </div>
            </md-field>

            <md-field :class="[getValidationClass('password'), 'md-field-auth']">
              <input name="password" id="password" type="password" :autocomplete="$t('register.password')"
                v-model="form.password" :disabled="isLoading" class="md-input-auth-password input-with-border"
                placeholder="Password" />
              <div class="md-error" v-if="!$v.form.password.required">
                {{ $t('register.requiredField') }}
              </div>
              <div class="md-error" v-else-if="!$v.form.password.minLength">
                {{ $t('register.minLengthPassword') }}
              </div>
            </md-field>
          </md-card-content>

          <md-card-actions class="register-actions">
            <div class="md-layout">
              <div class="md-layout-item md-size-100 md-alignment-left">
                <md-checkbox v-model="form.acceptTC" :disabled="isLoading"
                  class="md-alignment-left-box checkbox-container">
                  <span>
                    <router-link tag="span" :to="{ name: 'termsConditions' }" class="terms">
                      <a> Terms </a>
                    </router-link>
                    <span class="md-error" v-if="$v.form.acceptTC.$error">{{ $t('register.requiredField') }}</span>
                  </span>
                </md-checkbox>
              </div>
              <div class="md-layout-item md-size-100">
                <md-button type="submit" class="md-raised md-accent btn-full-width forgot-auth-btn" :disabled="isLoading">
                  {{ $t('register.registerBtn') }}
                </md-button>
              </div>
              <div class="md-layout-item md-size-100" style="text-align: center;">
                <p class="register-login-labels">{{ $t('register.haveAccount') }}
                  <router-link tag="span" :to="{ name: 'userLogin' }">
                    <a>{{ $t('register.signInBtn') }}</a>
                  </router-link>
                </p>
              </div>
            </div>
          </md-card-actions>

          <md-progress-bar class="md-accent" md-mode="indeterminate" v-if="isLoading" />
        </form>
      </md-card>
    </div>

    <div class="md-layout-item md-size-50 right-section register-right-section">
      <div class="benefits">
        <p class="big-text">
          <md-icon><i class="fas fa-check check-mark"></i></md-icon>
          {{ $t('register.visualBookmarks') }}
        </p>
        <p class="small-text"> {{ $t('register.visualBookmarksTxt1') }}
        </p>
        <br>
        <p class="big-text">
          <md-icon><i class="fas fa-check check-mark"></i></md-icon>
          {{ $t('register.discoverMore') }}
        </p>
        <p class="small-text">{{ $t('register.discoverMoreTxt') }}
        </p>
        <br>
        <p class="big-text">
          <md-icon><i class="fas fa-check check-mark"></i></md-icon>
          {{ $t('register.faveIt') }}
        </p>
        <p class="small-text">{{ $t('register.faveItTxt') }}
        </p>
        <br>
        <p class="big-text">
          <md-icon><i class="fas fa-check check-mark"></i></md-icon>
          {{ $t('register.everywhere') }}
        </p>
        <p class="small-text">{{ $t('register.everywhereTxt') }}
        </p>
        <br>
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import {
  required,
  minLength,
  email,
  sameAs,
} from 'vuelidate/lib/validators';
import formHelpersMixin from '@/mixins/formHelpersMixin';
import loaderMixin from '@/mixins/loaderMixin';
import { REGISTER } from '@/store/modules/auth/authActions.type';

export default {
  name: 'UserRegister',
  mixins: [
    validationMixin,
    formHelpersMixin,
    loaderMixin,
  ],
  data: () => ({
    form: {
      username: null,
      email: null,
      password: null,
      acceptTC: null,
    },
    publicPath: process.env.BASE_URL,
  }),
  validations: {
    form: {
      username: {
        required,
        minLength: minLength(3),
      },
      email: {
        required,
        email,
      },
      password: {
        required,
        minLength: minLength(5),
      },
      acceptTC: {
        required,
        sameAs: sameAs(() => true),
      },
    },
  },
  methods: {
    clearForm() {
      this.$v.$reset();
      this.form.username = null;
      this.form.email = null;
      this.form.password = null;
    },
    validateRegister() {
      this.$v.$touch();
      this.errors = {};

      if (!this.$v.$invalid) {
        this.registerAction();
      }
    },
    registerAction() {
      const payload = {
        username: this.form.username,
        email: this.form.email,
        password: this.form.password,
        source: 'wibki',
      };
      this.$log.info(REGISTER, payload);
      this.$store.dispatch(REGISTER, payload)
        .then((res) => {
          this.$toasted.show(res.message, {
            type: 'success',
            position: 'top-right',
            duration: 5000,
            icon: 'check',
          });
          this.$router.push(`confirm-email/${this.form.email}`);
          this.clearForm();
        })
        .catch((error) => {
          this.$log.error(REGISTER, error);
          this.$toasted.show(error.message, {
            type: 'error',
            position: 'top-right',
            duration: 5000,
            icon: 'alert',
          });
          if (error.status === 'validation_error' && error.errors) {
            this.errors = error.errors;
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/common/auth/auth-panel.scss';
@import '@/assets/scss/common/auth/register-responsive.scss';
</style>
